import React from "react";
import { Row, Col } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

const TermsAndCondition = (props) => {
	return (
        <>	
        <main id="main">
            <div className="container">
                <div className="row justify-content-md-center section-content" id="termsandcondition">
                    <Col md={12}>
                        <div className="text-center mt-4">
                            <h2>Olay Holiday Rebate Program Terms & Conditions</h2>
                            <p className="text-left">
                                <strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. BY ACCESSING OR PARTICIPATING IN OFFER, YOU AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ALL TERMS INCORPORATED BY REFERENCE.</strong>
                            </p>
                            
                            {/* {props.pdflink?<p className="mb-4 mt-2"><i>Please read carefully to ensure you agree and <a href="/public/assets/doc/P&G-Professional-Partner-Rewards-Terms-and-Conditions.pdf" target="_blank">save or print a copy</a> for your records.</i></p>:<p className="mb-4 mt-2"><i>Please read carefully to ensure you agree and save a copy for your records</i></p>} */}
                        </div>
                        <div className="mb-5">
                            <p className="text-left">
                                <strong>Offer Timing:</strong> The Olay Holiday Rebate Program (the “<strong>Offer</strong>”) begins on November 1, 2022 at 12:00 a.m. Eastern Time and ends December 31, 2022 at 11:59 p.m. Eastern Time (the “<strong>Offer Period</strong>”).
                            </p>
                            <p className="text-left">
                                <strong>Eligibility:</strong> The Offer is open only to legal residents of the 50 United States and the District of Columbia who are eighteen (18) years of age or older as of the last day of the month prior to date of Offer redemption. Employees of The Procter & Gamble Distributing LLC (the “<strong>Sponsor</strong>”), its parents, subsidiaries, divisions, affiliates, suppliers, distributors and advertising, promotional and judging agencies, and their immediate family members (spouses, parents, children, and siblings and their spouses) and household members of each (whether related or not), are not eligible to participate in the Offer. This Offer is void where taxed, prohibited, or restricted by law. All federal, state, and local laws and regulations apply. Federal, state and local taxes, if any, are the sole responsibility of participant.
                                </p>
                            <p className="text-left">
                                <strong>How to redeem the Offer and receive your choice of one (1) of eighteen (18) $25 Sponsor specified e-gift cards (the “Reward”), as follows:</strong>
                                </p>
                            <p className="text-left">
                                During the Offer Period, purchase $25 worth of participating Olay products (as outlined in Appendix A) at any participating retailer in one (1) or multiple transaction(s). Then, visit www.OlayHolidayGiftRebate.com (the “<strong>Website</strong>”) and complete all required registration information, including selecting the Reward you would like to receive and uploading a photo or screenshot of your original itemized, store-identified, dated receipt(s) that contain(s) the participating Olay products purchased during the Offer Period (the “<strong>Receipt</strong>”). Receipt(s) may be in the form of a photo of a printed out receipt or a screenshot of the online receipt at time of purchase.
                                </p>
                            <p className="text-left">
                                Offer must be redeemed online by 11:59 p.m. Eastern Time on January 31, 2023. If the Receipt(s) are illegible or is otherwise non-compliant with the Receipt requirements described herein, then a Reward will NOT be issued. <strong>Receipt(s) must contain at least $25 worth of participating Olay products to receive Reward before taxes after coupons and discounts.</strong>
                                </p>
                                <h4>Conditions and Disclaimers:</h4>
                            <p className="text-left">
                                <strong>Limit one (1) Reward per household</strong>. Upon validation/approval you will receive an email with a link to visit to claim your Reward. The Offer is valid only for consumer use, not for resale or for commercial or reseller accounts. This Offer is (i) not redeemable for cash or cash equivalents; (ii) is not valid on past or pre-ordered purchases. This Offer is subject to change or discontinuation without notice. Offer cannot be combined with, and is not valid with, any other rebate, offer, discount, promotion or program. Return of any Reward as undeliverable will result in forfeiture of Reward.
                                </p>
                            <p className="text-left">
                                Trust is a cornerstone of Sponsor’s corporate mission and the success of their business depends on it. Sponsor is committed to maintaining your trust by protecting personal information they collect about you. Click here for the full details of Sponsor’s Privacy Statement: <a href="http://www.pg.com/privacy/english/privacy_notice.html" target="_blank"> http://www.pg.com/privacy/english/privacy_notice.html</a>.
                                </p>
                            <p className="text-left">
                                Use of computer programs and other automatic means to participate in the Offer is prohibited. Any attempt by any participant to obtain more than the stated number of Offers by using multiple/different email addresses, identities, mailing addresses, registrations and logins, or any other methods will void such Offer redemptions. Normal Internet access and usage/data charges imposed by your online/phone service may apply. Sponsor reserves the right to substitute the Reward with an alternate Reward of equal or greater value in the event that the selected e-gift card becomes unavailable for any reason.
                            </p>
                            <p className="text-left">
                                Redeemed Offer will be declared to be made by the authorized account holder of the e-mail address submitted at the time of redemption. "Authorized account holder" of an e-mail address is defined as the natural person who is assigned to an e-mail address by an Internet Access provider, Online Service provider, or other organization (e.g., business, educational institution etc.) that is responsible for assigning e-mail addresses for the domain associated with the submitted e-mail address. In the event of a dispute regarding the identity of the person submitting a registration form, the registration will be deemed to be submitted by the person in whose name the e-mail account is registered. Offer redemptions generated by script or bot are not eligible. Sponsor is not responsible for any Offers that are returned as undeliverable.
                            </p>
                            <p className="text-left">
                                The Sponsor further reserves the right to cancel, terminate or modify the Offer at any time for any reason, in Sponsor’s sole discretion and all Offers properly submitted prior to cancellation will be awarded. Sponsor and their respective parent, subsidiary and affiliate companies are not responsible for late, misdirected, or incomplete Offers; or computer system, phone line, hardware, software or program malfunctions; or other errors, failures or delays in computer transmissions or network connections that are human or technical in nature. Sponsor and their respective parent, subsidiary and affiliate companies assume no responsibility or liability for damages, losses or injury resulting from the Offer.
                            </p>
                            <p className="text-left">
                                By accepting the Offer, the participant agrees to release and hold harmless Sponsor, and its respective subsidiaries, affiliates, suppliers, distributors, advertising/promotion agencies and each of their respective parent companies and each such company’s officers, directors, employees and agents (collectively, the “<strong>Released Parties</strong>”) from and against any claim or cause of action, including, but not limited to, personal injury, death, or damage to or loss of property, arising, in whole or in part, directly or indirectly, out of participation in the Offer or receipt or use or misuse of the Reward.
                            </p>
                            <p className="text-left">
                                ALL REWARDS ARE AWARDED “AS IS” AND WITHOUT ANY WARRANTY OF ANY KIND, EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE), AND ALL SUCH WARRANTIES ARE HEREBY DISCLAIMED.
                            </p>
                            <p className="text-left">
                                As a condition of participating in this Offer, participant: (1) agrees that all issues and questions concerning the construction, validity, interpretation and enforceability of these official terms and conditions, participant’s rights and obligations, or the rights and obligations of the Sponsor in connection with the Offer, shall be governed by and construed in accordance with the laws of the state of Ohio, without regard to conflicts of law provisions. Participants submit to exclusive personal jurisdiction in Ohio and agree that any dispute shall be brought in the state or federal courts in Ohio. Participants agree that: (a) any and all disputes, claims and causes of action arising out of, or connected with the Offer or any Rewards awarded shall be resolved individually, without resort to any form of class action, and exclusively by the appropriate court located in the State of Ohio; (b) any and all claims, judgments, and awards shall be limited to actual out-of-pocket costs incurred, including costs associated with participating in the Offer, but in no event attorney’s fees; and (c) notwithstanding the foregoing, participants waive the right to claim any damages, whatsoever, including, but not limited to, punitive, consequential, direct or indirect damages.
                            </p>
                            <p className="text-left">
                                If any provision of these Terms and Conditions is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions.
                            </p>
                            <p className="text-left">
                                These official Terms and Conditions constitute the entire agreement between Offer participants and the Sponsor pertaining to the subject matter hereof and supersedes all prior or other arrangements, understandings, negotiations and discussions, whether oral or written.  No waiver of any of the provisions of these official terms and conditions shall be deemed or shall constitute a waiver of any other provisions hereof (whether or not similar), nor shall waiver constitute a continuing waiver unless otherwise expressly provided.
                            </p>
                            <p className="text-left">
                                <strong>SPONSOR:</strong> The Sponsor of the Offer is The Procter & Gamble Distributing LLC, One P&G Plaza, Cincinnati, OH 45202.
                            </p>

                        </div>
                    </Col>
                </div>
            </div>
            </main>
        </>
	);
};

export default TermsAndCondition;