import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect } from 'react-redux';
import errorHtml from '../../components/Error';
import { resetPassword, resetPasswordTokenValidationCheckAction } from '../../redux/actions';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Required from '../../components/Required';
const ResetPassword = ({ setLoading, resetPassword, location }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [passwordConfirm, passwordConfirmSet] = useState('');
  const [password, setPassword] = useState('');
  const [isValidToken, setIsValidToken] = useState(true);
  const [errors, setErrors] = useState({ passwordConfirm: '', password: '' });
  const [token, setToken] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({});
  useEffect(() => {
    if (confige.uiConfig) {
      if (confige.uiConfig.passwordRule) {
        setPasswordValidation(confige.uiConfig.passwordRule);
      }
    }
  }, [confige, confige.uiConfig]);
  const validatePassword = (value, validationArray) => {
    let isValidPassword = true;
    let errormsg = '';
    for (let key in validationArray) {
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errormsg = t('Should not be less then', { count: validationArray[key] });
          // setErrors({ ...errors, password: t("Should not be less then", { count: validationArray[key] }) });
          isValidPassword = false;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errormsg = t('Should not be greater then', { count: validationArray[key] });
          // setErrors({ ...errors, password: t("Should not be greater then", { count: validationArray[key] }) });
          isValidPassword = false;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errormsg = t('Should not be empty');
          // setErrors({ ...errors, password: t("Should not be empty") });
          isValidPassword = false;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errormsg = t('Should have atleast one Upper Case latter');
          // setErrors({ ...errors, password: t("Should have atleast one Upper Case latter") });
          isValidPassword = false;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errormsg = t('Should have atleast one Lower Case latter');
          // setErrors({ ...errors, password: t("Should have atleast one Lower Case latter") });
          isValidPassword = false;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errormsg = t('Should have atleast one special letter');
          // setErrors({ ...errors, password: t("Should have atleast one special letter") });
          isValidPassword = false;
        }
      }
    }
    setErrors({ ...errors, password: errormsg });
    return isValidPassword;
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (!token) {
      setIsValidToken(false);
    } else {
      setLoading(true);
      resetPasswordTokenValidationCheckAction(token, {})
        .then(data => {
          if (data.httpCode === 404) {
            setIsValidToken(false);
            toast.error(`${t('Your password reset link is invalid')}.`);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          setIsValidToken(false);
        });
    }
    setToken(token);
    // eslint-disable-next-line
  }, []);

  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'passwordConfirm') {
      if (password !== value && value.length) {
        setErrors({ ...errors, passwordConfirm: t('Password and Confirm Password should be same') });
      } else {
        value.length < 1 ? setErrors({ ...errors, passwordConfirm: `${t('password')} ${t('Should not be empty')}` }) : setErrors({ ...errors, passwordConfirm: '' });
      }
      passwordConfirmSet(value);
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: `${t('password')} ${t('Should not be empty')}` }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
      validatePassword(value, passwordValidation);
    }
  };

  const handleError = () => {
    let value = false;
    const error = {};
    if (password.length < 1) {
      error.password = `${t('password')} ${t('Should not be empty')}`;
      value = true;
    }
    if (passwordConfirm.length < 1) {
      error.passwordConfirm = `${t('Confirm Password')} ${t('Should not be empty')}`;
      value = true;
    }
    if (password !== passwordConfirm && passwordConfirm.length) {
      error.passwordConfirm = t('Password and Confirm Password should be same');
      value = true;
    } else if (!passwordConfirm.length) {
      error.passwordConfirm = `${t('Confirm Password')} ${t('Should not be empty')}`;
      value = true;
    } else {
      error.passwordConfirm = '';
    }
    return { value, error };
  };

  const onSubmit = () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    if (validatePassword(password, passwordValidation)) {
      setLoading(true);
      resetPassword({ token, password })
        .then(response => {
          console.log(response);
          setLoading(false);
          toast.success(`Your password has been changed successfully. Please login to continue`);
          history.push('/login');
        })
        .catch(error => {
          console.log(error);
          error.map(item => {
            toast.error(item.message);
          });
          setLoading(false);
        });
    }
  };
  if (!isValidToken) {
    return <Redirect to={'login'} />;
  } else {
    return (
      <main id="main">
        <div className="register-page">
          <Card>
            <form aria-lable="Reset Password">
              <h1 className="main-heading pb-3">{`${t('Reset')} ${t('password')}`}</h1>
              <div className="row justify-content-center">
                <div className="col-md-7">
                  <div className="form-group row theme-form-password">
                    <label For="inputPassword" className="col-12 col-form-label input_label">
                      {t('password')} <Required />
                    </label>
                    <div className="col-12">
                      <input
                        type="password"
                        data-test="reset-password-input"
                        name="password"
                        onChange={onChangeHandle}
                        className="form-control"
                        id="inputPassword"
                        aria-label="New Password"
                        // placeholder={t("password")}
                      />
                    </div>
                    <div className="col-12 input-error" role="alert">
                      {errors.password}
                    </div>
                  </div>
                  <div className="form-group row theme-form-password-confirm">
                    <label htmlFor="inputPasswordConfirm" className="col-12 col-form-label input_label">
                      {t('Confirm Password')} <Required />
                    </label>
                    <div className="col-12">
                      <input
                        type="password"
                        data-test="reset-password-confirm-input"
                        name="passwordConfirm"
                        onChange={onChangeHandle}
                        className="form-control"
                        id="inputPasswordConfirm"
                        aria-label="Confirm Password"
                        // placeholder={t("password confirm")}
                      />
                    </div>
                    <div className="col-12 input-error" role="alert">
                      {errors.passwordConfirm}
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <button onClick={onSubmit} data-test="reset-password-submit" type="button" className="btn btn-primary">
                      {t('Save')}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Card>
        </div>
      </main>
    );
  }
};

export default connect(null, { resetPassword })(IsLoadingHOC(ResetPassword, 'Wait ....'));
