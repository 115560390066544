import React, { useState } from 'react';
import { Container, Row, Col, Accordion, Card, Button, Nav } from 'react-bootstrap';
const FrequentlyAskedQuestions = () => {
  const [data, setData] = useState([
    { id: 0, clicked: false, name: 'How long do I have to submit my receipt?', content: 'All products must be purchased between June 15, 2023 and September 30, 2023. You have until October 5, 2023 at 11:59PM EST to upload your receipt.' },
    { id: 1, clicked: false, name: 'What do I do if I am having trouble participating in the program?', content: "You can submit any questions you may have about the promotion to our <a href='/support'>support box</a>." },
    { id: 2, clicked: false, name: 'What are the participating products?', content: 'Organic Treo Strawberry 16oz<br/>Organic Treo Peach Mango 16oz<br/>Organic Treo Raspberry Lemonade 16oz<br/>Organic Treo Blueberry 16oz<br/>' },
    { id: 3, clicked: false, name: 'How many times can I submit the same valid receipt?', content: 'You can only submit a valid receipt once, receipts submitted more than once will be rejected.' },
    { id: 4, clicked: false, name: 'How many rewards can I get?', content: 'There is a limit of three (3) rewards per email address.' },
    { id: 5, clicked: false, name: 'Do I have to purchase all 5 in one transaction?', content: 'Yes, you must purchase 5 organic TREO products at Walmart before taxes and coupons in one transaction between June 15, 2023 and September 30, 2023 to be eligible.' },
    { id: 6, clicked: false, name: 'What do I do if I have additional questions?', content: "Review the <a href='/public/assets/terms_conditions_new.pdf' target='_blank'>Terms and Conditions</a> for the offer for additional details. If the <a href='/public/assets/terms_conditions_new.pdf' target='_blank'>Terms and Conditions</a> have not answered your question, please email us via the <a href='/support'>support box</a>." },
    { id: 7, clicked: false, name: 'Can I enter without purchasing?', content: "Yes, you can fill out our <a href='/choose-exp'>No Purchase Necessary form here</a> a maximum of 1 time per day up to 25 times over the promotional period." },
  ]);
  const [faqStatus, setFaqStatus] = useState(false);
  const toggleHandler = i => {
    let tempData = JSON.parse(JSON.stringify(data));
    tempData[i].clicked = tempData[i].clicked ? false : true;
    setData(tempData);
  };
  const bulkActionHandler = () => {
    let status = !faqStatus;
    setFaqStatus(status);
    let tempData = data.map(v => ({ ...v, clicked: status }));
    setData(tempData);
  };
  return (
    <main id="main">
      <Container>
        <Row className="justify-content-md-center section-content faq" id="faq-content">
          <Col md={12}>
            <h1 className="section-heading text-center pb-3">Frequently Asked Questions</h1>
          </Col>
          <Col md={11} className="text-right my-3">
            <Button onClick={bulkActionHandler} variant="link">
              {!faqStatus ? 'Open All' : 'Close All'}
            </Button>
          </Col>
          <Col md={11}>
            <Accordion>
              {data.map((v, i) => {
                return (
                  <Card style={{ marginBottom: '1rem' }}>
                    <Card.Header>
                      <Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={e => toggleHandler(i)} eventKey={v.id}>
                        <Row style={{ cursor: 'pointer' }}>
                          <Col xs={11}>{v.name}</Col>
                          <Col xs={1} className="text-right pl-0 faq_open-close-icon">
                            {v.clicked ? '−' : '+'}
                          </Col>
                        </Row>
                      </Accordion.Toggle>
                    </Card.Header>
                    {v.clicked ? (
                      <Accordion>
                        <Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
                      </Accordion>
                    ) : (
                      <Accordion.Collapse>
                        <Card.Body dangerouslySetInnerHTML={{ __html: v.content }} />
                      </Accordion.Collapse>
                    )}
                  </Card>
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default FrequentlyAskedQuestions;
