import React from 'react';
import { getManu } from '../../redux/actions';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import CopyRight from './../CopyRight';
//import { useTranslation } from "react-i18next";
const Footer = () => {
  //const { t } = useTranslation();

  const handleShow = () => {
    if (window.Optanon) {
      window.Optanon.ToggleInfoDisplay();
    }
  };
  return (
    <footer>
      <Container fluid className="footer">
        <div className="footer-link" aria-label="footerMenu">
          <ul>
            <li>
              <a href="/public/assets/terms_conditions_new.pdf" target="_blank">
                Terms and Conditions
              </a>
            </li>
            <li>|</li>
            <li>
              <a href="https://drinktreo.com/pages/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="row justify-content-center footer-offer">
          <div className="col-12 text center">
            <p>
              Buy 5 or more (after any coupon or discount applied and before taxes) participating products between June 15, 2023 and September 30, 2023 and receive a digital reward. Terms and Conditions apply. US Residents only. 18 years of age or older. Maximum one (1) Reward per approved submission. Limit three (3) Reward(s) per person. While supplies last. Proof of purchase required. Issued as a digital card. Eligible purchases must be made at a US in-store or online Walmart retailer.
              Submissions must be received by October 5, 2023 at 11:59 PM ET.
            </p>
          </div>
        </div>
        <CopyRight />
      </Container>
    </footer>
  );
};
export default connect(null, { getManu })(Footer);
