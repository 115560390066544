import React, { useEffect, useState } from 'react';
import Card from './Card';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { portalPointAction, activityStatusAction } from '../redux/actions';
import { Button, Container, Col, Row, Modal } from 'react-bootstrap';
import IsLoadingHOC from '../components/IsLoadingHOC';
import { Trans } from 'react-i18next';
const PortalPointList = props => {
  const [data, setData] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [viewMore, setViewMore] = useState(true);
  const [page, setPage] = useState(0);
  const [pageRedirect, setPageRedirect] = useState(false);
  const pagesize = props.pagesize ? props.pagesize : 4;

  useEffect(() => {
    getNextData(0);
    // eslint-disable-next-line
  }, []);
  const getNextData = page => {
    const actionType = ['view', 'survey', 'refer', 'read', 'share'];
    props.setLoading(true);
    props
      .portalPointAction(page + 1, actionType, pagesize)
      .then(response => {
        let activityList = response.data;
        if (activityList) {
          activityList = activityList.map(v => {
            let o = Object.assign({}, v);
            if (o.isTierEnabled && o.tierPoints) {
              o.points = o.tierPoints;
            }
            return o;
          });
        }
        setData([...data, ...activityList]);
        props.parentCallBack(activityList);
        setPage(page + 1);
        setViewMore(response.end);
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };
  const activityCLick = async (activityData, redirectUrl) => {
    try {
      if (activityData['actionType'] === 'survey') {
        props.setLoading(true);
        let activityStatus = await activityStatusAction(activityData.id);
        if (activityStatus['allow']) {
          setPageRedirect(redirectUrl);
        } else {
          setPopupShow(true);
          console.log('not allow', activityStatus);
        }
        props.setLoading(false);
      } else {
        setPageRedirect(redirectUrl);
      }
    } catch (err) {
      console.log('Error in Activity status check', err);
      props.setLoading(false);
    }
  };
  const handlePopupClose = () => {
    setPopupShow(false);
  };
  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else {
    return (
      <Container fluid>
        <div className="portal-point-list-container">
          {data.map((item, index) => {
            return (
              <div className="row py-5">
                <div className="container content-container">
                  {/* <div className="row" key={index}>
                    <div className="col-sm-6 choose-exp d-sm-flex d-block align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <img src="/public/assets/images/upload-receipt.png" className="img-fluid" alt="receipt" />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <a href="/upload-receipt" className="btn btn-primary">
                              $5 Walmart Gift Card
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 choose-exp d-sm-flex d-block align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-4 d-flex align-items-center">
                          <img src="/public/assets/images/vip-exp.png" className="img-fluid" alt="Redeem Code" />
                        </div>
                        <div className="col-md-8">
                          <div className="card-body">
                            <a onClick={e => activityCLick(item, `/npn/${item.id}`)} className="btn btn-primary">
                              VIP Experience
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="choose-exp-container">
                    <Row md={2} xs={1} sm={1} className="py-5 ">
                      <Col className="choose-exp-btn">
                        <a href="/upload-receipt" className="btn btn-primary upload-btn">
                          $5 Walmart Gift Card
                        </a>
                      </Col>
                      <Col className="choose-exp-btn">
                        <a onClick={e => activityCLick(item, `/npn/${item.id}`)} className="btn btn-primary vip-btn">
                          VIP Experience
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Modal show={popupShow} onHide={handlePopupClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Sorry</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="survey-message-div">
              <p className="alignCenter">
                <Trans>You have already earned the maximum number of points possible for this action</Trans>.
              </p>
            </div>
          </Modal.Body>
          <p align="center">
            <Link to={'#'} className="btn btn-link" onClick={handlePopupClose}>
              <Trans>Close this window</Trans>
            </Link>
          </p>
          <p align="center">
            <Link className="btn btn-link" onClick={handlePopupClose} to="/">
              <Trans>Go back to home</Trans>
            </Link>
          </p>
        </Modal>
      </Container>
    );
  }
};

export default connect(null, { portalPointAction })(IsLoadingHOC(PortalPointList, 'Loading....'));
