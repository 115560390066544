import React, { useState } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import { login } from '../../redux/actions';
import { useHistory } from 'react-router-dom';
import SocialLogin from '../../components/SocialLogin';
import { Trans, useTranslation } from 'react-i18next';
import SecondFactorAuth from '../../components/SecondFactorAuth';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import Captcha from '../../components/Captcha';
import Required from '../../components/Required';

var validator = require('validator');

const Login = ({ setLoading, login }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const confige = useSelector(state => state.app.config);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [showSecondFactorPopup, setShowSecondFactorPopup] = useState(false);
  const [secondFactorAuthData, setSecondFactorAuthData] = useState({});
  const [errors, setErrors] = useState({ username: '', password: '', captchaVal: '' });
  const [fpData, setFpData] = useState({ visitorId: '', requestId: '' });
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  const [resetCaptchaCnt, setResetCaptchaCnt] = useState(0);
  const onChangeHandle = e => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUserName(e.target.value);

      if (value.length < 1) {
        setErrors({ ...errors, username: t('Email Address should not be empty.') });
      } else if (!validator.isEmail(e.target.value)) {
        setErrors({ ...errors, username: t('Please enter a valid email address.') });
      } else {
        setErrors({ ...errors, username: '' });
      }
    }
    if (name === 'password') {
      value.length < 1 ? setErrors({ ...errors, password: t('Password Should not be empty') }) : setErrors({ ...errors, password: '' });
      setPassword(e.target.value);
    }
  };
  const [redirectUrl, setRedirectUrl] = useState('/choose-exp');
  React.useEffect(() => {
    if (confige.campaignState === 'partialExpired' || confige.submissionEnded === 'submissionExpired') {
      history.push('/');
      toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
    }
    if (confige.fingerprinting) {
      if (confige.fingerprinting.enable) {
        const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
        // Get the visitor identifier when you need it.
        fpPromise
          .then(fp => fp.get())
          .then(result => {
            setFpData({ visitorId: result.visitorId, requestId: result.requestId });
          });
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.loginCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [confige]);
  React.useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(errors));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
    }
    setErrors(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  React.useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/choose-exp';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const handleError = () => {
    let value = false;
    const error = {};
    if (username.length < 1) {
      error.username = t('Email Address should not be empty');
      value = true;
    }
    if (password.length < 1) {
      error.password = t('Password Should not be empty');
      value = true;
    }
    if (password.length < 1) {
      error.password = t('Password Should not be empty');
      value = true;
    }
    if (!captchaValue && captchaEnable) {
      error.captchaVal = t('Please select captcha');
      value = true;
    }
    return { value, error };
  };

  const onSubmit = () => {
    const { value, error } = handleError();
    if (value) {
      setErrors({ ...errors, ...error });
      return false;
    }
    setLoading(true);
    let requestBody = { username, password, visitorId: fpData.visitorId, requestId: fpData.requestId };
    if (captchaEnable) {
      requestBody['captchaVal'] = captchaValue;
    }
    login(requestBody)
      .then(response => {
        setResetCaptchaCnt(resetCaptchaCnt + 1);
        setLoading(false);
        if (response.httpCode === 204) {
          setSecondFactorAuthData({ id: response.data });
          setShowSecondFactorPopup(true);
        } else {
          toast.success(t('User login successfully.'));
          history.push(redirectUrl);
        }
      })
      .catch(error => {
        console.log('Error in login', error);
        setResetCaptchaCnt(resetCaptchaCnt + 1);
        if (error['httpCode'] === 403) {
          toast.error(`${t('Your account is suspended. Please contact helpdesk for more information')}.`);
        } else if (error['httpCode'] === 406) {
          toast.error(`${t('Error')}! ${t('User not verified')}`);
        } else if (error['httpCode'] === 404) {
          toast.error(`${t('User not found! Please sign up before login')}.`);
        } else {
          toast.error(`${t('Invalid credentials')}.`);
        }
        setLoading(false);
      });
  };

  return (
    <main id="main">
      <div className="register-page">
        <Card>
          <form aria-label="login">
            <h1 className="main-heading pb-3">
              <Trans>login</Trans>
            </h1>
            <div className="row justify-content-center">
              <div className="col-md-7">
                <div className="form-group row theme-form-email justify-content-center">
                  <label className="col-12 col-form-label input_label" htmlFor="staticEmail">
                    Email Address <Required />
                  </label>
                  <div className="col-12">
                    <input type="text" data-test="sign-in-username-input" name="username" onChange={onChangeHandle} className="form-control" id="staticEmail" aria-label="Enter registered email" aria-autocomplete="Your-email" />
                    <span className="input-error" role="alert">
                      {errors.username}
                    </span>
                  </div>
                </div>
                {/*[START:PASSWORD]*/}
                <div className="form-group row theme-form-password justify-content-center pt-2">
                  <label className="col-12 col-form-label input_label" htmlFor="inputPassword">
                    Password <Required />
                  </label>
                  <div className="col-12">
                    <input type="password" data-test="sign-in-password-input" name="password" onChange={onChangeHandle} className="form-control" id="inputPassword" onKeyPress={e => e.key === 'Enter' && onSubmit()} aria-label="Enter your password" />
                    <span className="input-error" role="alert">
                      {errors.password}
                    </span>
                  </div>
                </div>
                {/*[END:PASSWORD]*/}
                {/*[START:GOOGLECAPTCHA]*/}
                {captchaEnable ? (
                  <div className="form-group row justify-content-center">
                    <div className="">
                      <label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
                        Google captcha
                      </label>
                      <Captcha reset={resetCaptchaCnt} parentCallback={setCaptchaValue} />
                      <span className="input-error" role="alert">
                        {errors.captchaVal}
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {/*[END:GOOGLECAPTCHA]*/}

                <div className="row justify-content-center mt-3">
                  <button onClick={onSubmit} data-test="sign-in-sign-in-button" type="button" className="btn btn-primary px-5">
                    <Trans>login</Trans>
                  </button>
                </div>
                <div className="my-4">
                  <SocialLogin />
                </div>
                <div className="forgot-pass-links text-center mt-3">
                  <a href="/forget-password" aria-label="Click to generate new password">
                    <Trans>forgot password</Trans>
                  </a>
                </div>
                <div className="register-links text-center mt-0">
                  Not registered yet?{' '}
                  <a href="/register" aria-label="Click to register">
                    <Trans>Click here</Trans>
                  </a>
                </div>
              </div>
            </div>
          </form>
        </Card>
        <Modal show={showSecondFactorPopup}>
          <Modal.Body>{showSecondFactorPopup ? <SecondFactorAuth otpLen={6} waitingTime={120} data={secondFactorAuthData} /> : ''}</Modal.Body>
        </Modal>
      </div>
    </main>
  );
};

export default connect(null, { login })(IsLoadingHOC(Login, 'Wait ....'));
