import React, { Fragment } from 'react';
import { logo } from '../data/index';
import { Link } from 'react-router-dom';
const Logo = () => {
  return (
    <Fragment>
      <a className="navbar-brand" aria-label="Brand Logo" href="/" title="TREO Summer Offer">
        <img alt="TREO Summer Offer Logo" src={logo}></img>
      </a>
    </Fragment>
  );
};
export default Logo;
