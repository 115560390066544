import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PortalPointList from './PortalPointList';

const ParticipateInstructions = () => {
  const user = useSelector(({ user }) => user);
  const [data, setData] = React.useState([]);
  return (
    <Row className="justify-content-md-center text-center section-content" id="participate-content" aria-label="content">
      <Col md={9}>
        <h1>How To Participate</h1>
      </Col>
      <Col md={9}>
        <h2>1. BUY</h2>
        <p>Purchase 5 organic TREO products at Walmart between June 15, 2023 and September 30, 2023.</p>

        <h2>2. TAKE A PICTURE</h2>
        <p>Snap a photo of your receipt showing your qualifying purchases. Don’t forget that the photo of each receipt must display the date and time of the transaction and the qualifying products purchased. If the receipt is too long, please fold the receipt so that the date and time and qualifying purchases are included in the picture.</p>
        {!user.accessToken ? (
          <>
            <h2>3. REGISTER & UPLOAD</h2>
            <p>
              To register, please{' '}
              <a title="register here" href="/register">
                click here
              </a>
              . Once you complete registration, you will be prompted to upload your receipt. Look out for an email confirming your receipt was uploaded and received. Receipts can take up to two business days to process and will be subject to verification before approval is received, so please be patient. All eligible receipts MUST be uploaded by October 5, 2023.
            </p>
          </>
        ) : (
          <>
            <h2>3. Upload & No Purchase Necessary Entries</h2>
            <p>Upload your receipt or tell us why you want to win the VIP experience to enter into the sweepstakes with no purchase necessary. Once you have uploaded your receipt, look out for an email confirming your receipt was uploaded and received. Receipts can take up to two business days to process and will be subject to verification before approval is received, so please be patient. All eligible receipts MUST be uploaded by October 5, 2023.</p>
          </>
        )}
        <h2>4. Enter to Win a VIP Concert Experience with Jason Derulo</h2>
        <p>Every approved receipt earns you one entry to win the VIP Concert Experience. To earn more entries, you can answer our question about why you’d like to win. You can enter this way once per day up to a maximum of 25 times over the contest period.</p>
      </Col>
      {/* <>
        {!user.accessToken ? (
          <Col md={9} className="text-center">
            <a className="btn btn-primary mr-5" title="$5 WALMART GIFT CARD" href="/register">
              $5 GIFT CARD
            </a>
            <a className="btn btn-primary" title="VIP EXPERIENCE" href="/register">
              VIP EXPERIENCE
            </a>
          </Col>
        ) : (
          <Col md={9} className="text-center htp-choose-exp">
            <PortalPointList pagesize={8} parentCallBack={setData} />
          </Col>
        )}
      </> */}
    </Row>
  );
};

export default ParticipateInstructions;
