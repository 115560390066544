import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { toast } from 'react-toastify';
import { uploadImageAction, validateUploadReceiptAction } from '../../redux/actions';
import PreviousReceiptList from './PreviousReceiptList';
import FileDropZone from '../FileDropZone';
import { useSelector } from 'react-redux';
import Captcha from '../Captcha';
import { generatePdfFromImages } from '../../hooks/ImagesToPDF';
const UploadReceipt = props => {
  const { t } = useTranslation();
  const config = useSelector(state => state.app.config);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [selectedFile, setSelectedFile] = useState([]);
  const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
  let [enableUploading, setEnableUploading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  React.useEffect(() => {
    if (config.receiptUploadPreValidation) {
      checkUploadEnableOrNot(false);
    }
    if (config.captchaConfig) {
      if (config.captchaConfig.uploadReceiptCaptcha) {
        setCaptchaEnable(true);
      }
    }
    //eslint-disable-next-line
  }, [config]);
  const checkUploadEnableOrNot = uploadApiCall => {
    props.setLoading(true);
    validateUploadReceiptAction({})
      .then(responseData => {
        props.setLoading(false);
        //setReceiptLoadCounter(receiptLoadCounter + 1);
        if (responseData['httpCode'] >= 200 && responseData['httpCode'] <= 299) {
          setEnableUploading(responseData.data.valid);
          if (uploadApiCall) {
            uploadFileHandler(responseData.data.token);
          }
          return true;
        } else {
          toast.error(`${t('Error')}! ${t('You have reached the program limit for rewards')}.`);
        }
      })
      .catch(err => {
        console.log('error', err);
        toast.error(`${t('Error')}! ${t('You have reached the program limit for rewards')}.`);
        props.setLoading(false);
      });
  };
  const uploadImage = () => {
    if (selectedFile.length === 0) {
      toast.error(`${t('Error')}! ${t('Select a receipt')}`);
      return false;
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}`);
      return false;
    }
    if (config.receiptUploadPreValidation) {
      checkUploadEnableOrNot(true);
    } else {
      uploadFileHandler('');
    }
  };
  const uploadFileHandler = async token => {
    let fileData = selectedFile[0];
    if (selectedFile.length > 1) {
      fileData = await generatePdfFromImages(selectedFile);
    }
    const formData = new FormData();
    formData.append('type', 'receipt');
    formData.append('image', fileData);
    // let ocrStatus = JSON.parse(JSON.stringify({ parseWithOcr: false}));
    // formData.append("additionalInfo", JSON.stringify(ocrStatus));
    if (token) {
      formData.append('token', token);
    }
    if (captchaEnable) {
      formData.append('captchaVal', captchaValue);
    }
    props.setLoading(true);
    uploadImageAction(formData)
      .then(responseData => {
        props.setLoading(false);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        setResetCaptchaValue(resetCaptchaValue + 1);
        setReceiptLoadCounter(receiptLoadCounter + 1);
        if (responseData['httpCode'] === 200) {
          toast.success('Your receipt/s have been uploaded successfully. We shall get back to you in one to two business days.');
          setSelectedFile([]);
          return true;
        } else if (responseData['httpCode'] === 409) {
          toast.error(`${t('Error')}! This receipt has already been uploaded. Please upload another receipt.`);
          setSelectedFile([]);

          return true;
        } else {
          toast.error(`${t('Error')}! ${t('Something went wrong')}`);

          return false;
        }
      })
      .catch(err => {
        setResetCaptchaValue(resetCaptchaValue + 1);
        console.log('error', err);
        props.setLoading(false);
        if (typeof err === 'object') {
          if (err['response']) {
            if (err['response'].httpCode === 409) {
              //duplicate Receipt message
              toast.error(`${t('Error')}! This receipt has already been uploaded. Please upload another receipt.`);
              setSelectedFile([]);
              return false;
            } else {
              toast.error(`${t('Error')}! ${t('Something went wrong')}`);
              return false;
            }
          } else {
            toast.error(`${t('Error')}! ${t('Something went wrong')}`);
            return false;
          }
        } else {
          toast.error(`${t('Error')}! ${t('Something went wrong')}`);
          return false;
        }
      });
  };
  const resetAllSelectionBtnClick = () => {
    setSelectedFile([]);
    setResetCaptchaValue(resetCaptchaValue + 1);
  };
  const handleHrefClick = e => {
    e.preventDefault();
    setModalShow(true);
  };
  return (
    <main id="main">
      <Container fluid>
        <div className="validate-purchase-div">
          <Col>
            <Row xs={1} md={1} lg={1}>
              <h1 className="section-heading heading text-center">
                <Trans>Upload your receipt</Trans>
              </h1>
            </Row>
            <Row xs={1} md={1} lg={1}>
              <div className="h2-disc1" role="heading" aria-level="2">
                <Trans>Take a clear picture of your entire receipt. The receipt must include the retailer name, participating products purchased and date of purchase in the photo.</Trans>
              </div>
              <p align="center">
                Did you purchase online?{' '}
                <a className="btn-link" href="#" onClick={handleHrefClick}>
                  Click here
                </a>{' '}
                for details!
              </p>
            </Row>
            <Row xs={1} md={1} lg={1}>
              <label className="label-disc">
                {/* <Trans>Note</Trans>: <Trans>Size of the receipt must be less than</Trans> 5 MB. */}
                <Trans>Note</Trans>: Uploaded images should be the same format - JPG, PNG, or PDF are accepted. If a combination of images and PDF are selected only the last PDF will be uploaded.
              </label>
            </Row>
            <Row className="justify-content-md-center">
              <Col md="12">
                <PreviousReceiptList reloadCounter={receiptLoadCounter} />
              </Col>
            </Row>
            <Row>
              <FileDropZone enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
            </Row>
            {captchaEnable ? (
              <Row md={1} className="mt-3">
                <Col>
                  <label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
                    Google captcha
                  </label>
                  <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
                </Col>
              </Row>
            ) : (
              ''
            )}
            {enableUploading ? (
              <Row md={2} xs={2} sm={2}>
                <Col className="text-right">
                  <Button size="lg" type="button" onClick={uploadImage} className="btn btn-primary" id="upload-btn">
                    <Trans>Submit</Trans>
                  </Button>
                </Col>
                <Col>
                  <Button size="lg" type="button" onClick={resetAllSelectionBtnClick} className="btn btn-warning" id="upload-btn">
                    <Trans>Reset</Trans>
                  </Button>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Col>
        </div>
      </Container>
      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered onHide={() => setModalShow(false)} className="pb-5">
        <Modal.Header closeButton />
        {/* <Modal.Title id="contained-modal-title-vcenter">Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <p>For online purchases, it's best to save your invoice in PDF format and upload it. That way, we will have all the necessary information to help get your receipt approved. ☺️ </p>
          <p>How to get your Online Purchase Receipt in PDF format Please review steps 1-4.</p>
          <p>1. Open the email purchase confirmation.</p>
          <p>
            2. Find and click on the Print icon. (See below)
            <br />
            <img alt="image1" src="/public/assets/images/image1.png" />
          </p>
          <p>
            3. Change the 'Destination' of the document from the Print Window. (See below)
            <br />
            <img alt="image2" src="/public/assets/images/image2.png" />
          </p>
          <p>
            4. Select "Save as PDF." (See below) - and click SAVE
            <br />
            <img alt="image3" src="/public/assets/images/image3.png" />
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center center">
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
};

export default IsLoadingHOC(UploadReceipt, 'Loading....');
