import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { carouselData } from '../data';

const CarouselCustom = () => {
  const user = useSelector(({ user }) => user);
  const config = useSelector(state => state.app.config);
  const [isActiveSite, setIsActiveSite] = React.useState(true);
  React.useEffect(() => {
    if (config.campaignState === 'partialExpired' || config.submissionEnded === 'submissionExpired') {
      setIsActiveSite(false);
    }
    //eslint-disable-next-line
  }, [config]);

  return (
    <div className="justify-content-center" role="banner">
      {!user.accessToken && isActiveSite ? (
        <div class="img-container">
          <div class="treo-img">
            <img className="d-block w-100  banner-img desktop-hide" src="/public/assets/images/desktop-banner-revised.png" alt={`THIS SUMMER, DRINK DIFFERENT. WHEN YOU BUY 5 FLAVORS OF ORGANIC TREO AT WALMART. ( ONLINE OR IN STORE)`} aria-labelledby="banner-text" useMap="#before-desktop-image-map" />
            <map name="before-desktop-image-map">
              <area target="_self" alt="upload-receipt" title="upload-receipt" href="/register" coords="390,886,691,951" shape="rect" />
            </map>
            <img className="d-block w-100 mobile-hide banner-img" src="/public/assets/images/mobile-banner.png" alt={`THIS SUMMER, DRINK DIFFERENT. WHEN YOU BUY 5 FLAVORS OF ORGANIC TREO AT WALMART. (ONLINE OR IN STORE)`} aria-labelledby="banner-text" useMap="#before-mobile-image-map" />
            <map name="before-mobile-image-map">
              <area target="_self" alt="upload-receipt" title="upload-receipt" href="/register" coords="237,1325,543,1392" shape="rect" />
            </map>
          </div>
        </div>
      ) : (
        <div class="img-container">
          <div class="treo-img">
            <img className="d-block w-100  banner-img desktop-hide" src="/public/assets/images/desktop-banner-revised.png" alt={`THIS SUMMER, DRINK DIFFERENT. WHEN YOU BUY 5 FLAVORS OF ORGANIC TREO AT WALMART. ( ONLINE OR IN STORE)`} aria-labelledby="banner-text" useMap="#after-desktop-image-map" />
            <map name="after-desktop-image-map">
              <area target="_self" alt="upload-receipt" title="upload-receipt" href="/upload-receipt" coords="390,886,691,951" shape="rect" />
            </map>
            <img className="d-block w-100 mobile-hide banner-img" src="/public/assets/images/mobile-banner.png" alt={`THIS SUMMER, DRINK DIFFERENT. WHEN YOU BUY 5 FLAVORS OF ORGANIC TREO AT WALMART. (ONLINE OR IN STORE)`} aria-labelledby="banner-text" useMap="#after-mobile-image-map" />
            <map name="after-mobile-image-map">
              <area target="_self" alt="upload-receipt" title="upload-receipt" href="/upload-receipt" coords="237,1325,543,1392" shape="rect" />
            </map>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselCustom;
